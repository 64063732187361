// Override the icons path
$ag-icons-path: '~ag-grid/src/styles/icons/';
@import '_variables.scss';
/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import '~ag-grid-community/dist/styles/ag-theme-material.css';
// @import '~ag-grid/src/styles/ag-theme-material';

.mat-drawer-content {
  display: block !important;
  overflow: hidden !important;
  background-color: #eee;
}

.cursor-pointer {
  cursor: pointer;
}

ul.legend-labels.horizontal-legend {
  text-align: center;
}

.bg-white {
  background-color: #fff;
}

::ng-deep .ag-root-wrapper {
  position: static !important;
}

.white-box {
  border: 1px solid #d3d8e0;
  background-color: #fff;
  margin-top: 20px;
  padding: 18px 20px;
  box-sizing: border-box;
  font-family: Open Sans, sans-serif;
}

.report-disclaimer {
  font-family: mat-font-family($finra-typography-config);
  color: rgb(174, 182, 193);
  padding-top: 20px;
  white-space: pre-line;
}

.height-full {
  height: 100%;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.alert-box {
  padding: 10px;
  border-radius: 4px;
}

// columns //

// meant to be used on a parent/row element
.col-flex-grow {
  & .col {
    flex-grow: 1;
  }
}

.col-5 {
  width: 5%;
}
.col-10 {
  width: 10%;
}
.col-15 {
  width: 15%;
}
.col-20 {
  width: 20%;
}
.col-25 {
  width: 25%;
}
.col-30 {
  width: 30%;
}
.col-33 {
  width: 33.33%;
}
.col-35 {
  width: 35%;
}
.col-40 {
  width: 40%;
}
.col-45 {
  width: 45%;
}
.col-50 {
  width: 50%;
}
.col-55 {
  width: 55%;
}
.col-60 {
  width: 60%;
}
.col-65 {
  width: 65%;
}
.col-67 {
  width: 66.67%;
}
.col-70 {
  width: 70%;
}
.col-75 {
  width: 75%;
}
.col-80 {
  width: 80%;
}
.col-85 {
  width: 85%;
}
.col-90 {
  width: 90%;
}
.col-95 {
  width: 95%;
}
.col-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.snackbar-success {
  margin-top: 10px !important;
  background: mat-color($finra-green, 100);
  border: solid mat-color($finra-green, 600) 1px;
  color: map_get(map_get($app-theme, foreground), base);
  simple-snack-bar {
    padding: 10px 0;
  }
  .simple-snack-bar {
    padding: 10px 0;
  }
}

.snackbar-error {
  margin-top: 10px !important;
  background: mat-color($finra-red, 100);
  border: solid mat-color($finra-red, 600) 1px;
  color: map_get(map_get($app-theme, foreground), base);
  simple-snack-bar {
    padding: 10px 0;
  }
  .simple-snack-bar {
    padding: 10px 0;
  }
}

.mat-snack-bar-container {
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 24px;
  max-width: 33vw;
  min-width: 344px;
  padding: 14px 16px;
  min-height: 48px;
  transform-origin: center;
}

.snackbar-warning {
  margin-top: 10px !important;
  background: mat-color($finra-yellow, 100);
  border: solid mat-color($finra-yellow, 600) 1px;
  color: map_get(map_get($app-theme, foreground), base);
  simple-snack-bar {
    padding: 10px 0;
  }
  .simple-snack-bar {
    padding: 10px 0;
  }
}

.snackbar-info {
  margin-top: 10px !important;
  background: mat-color($finra-blue, 100);
  border: solid mat-color($finra-blue, 600) 1px;
  color: map_get(map_get($app-theme, foreground), base);
  simple-snack-bar {
    padding: 10px 0;
  }
  .simple-snack-bar {
    padding: 10px 0;
  }
}

.transition {
  -webkit-transition: transition 0.3s;
  -moz-transition: transition 0.3s;
  -o-transition: transition 0.3s;
  -ms-transition: transition 0.3s;
  transition: 0.3s;
}

.title-color {
  color: #233e66;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.gap-3 {
  gap: 3;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.m-0 {
  margin: 0;
}
.p-0 {
  padding: 0;
}

.question-icon-container {
  font: 600 16px Open Sans,sans-serif;
  color: mat-color($finra-light-blue, 500);
  cursor: pointer !important;
}

.question-icon {
  color: mat-color($finra-light-blue, 500) !important;
  font-size: larger;
  font-weight: bolder;
  padding-right: 10px;
}

span.audit-action-tag {
  // font-size: small;
  color: white;
  border-radius: 12px;
  padding: 5px 10px;
  margin: 3px;
  line-height: 15px;
  display: inline-flex;
  background-color: mat-color($finra-light-blue, 500);
}
