@import '~@angular/material/theming';

$fa-font-path : '../../../../../node_modules/font-awesome/fonts';

$finra-blue: (
  50: #e5e8ed,
  100: #d3d8e0,
  200: #7b8ba3,
  300: #5f7eab,
  400: #445b7d,
  500: #233e66,
  600: #1f385e,
  700: #1a3053,
  800: #152849,
  900: #0c1b38,
  A100: #729dff,
  A200: #3f7aff,
  A400: #0c56ff,
  A700: #004af1,
  A800: #4F6585,
  A900: #a7b2c2,
  B100: #e9ebef,
  B200: #f3f4f6
,  contrast:
    (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff
    )
);

$finra-light-blue: (
  20: #f3f6f9,
  50: #cce6f6,
  100: #99cded,
  200: #66b4e3,
  300: #339bda,
  400: #05a1ff,
  500: #0082d1,
  600: #0072b7,
  700: #00629e,
  800: #005284,
  900: #00436b,
  A100: #6bc7ff,
  A200: #84d1ff,
  A400: #9edaff,
  A700: #003351,
  A800: #1D82D1,
  contrast:
    (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #ffffff
    )
);

$finra-yellow: (
  100: #fff3d7,
  200: #ffeab3,
  300: #ffe090,
  400: #ffd86d,
  500: #ffcf40,
  600: #e5b939,
  700: #cca533,
  800: #005284,
  900: #00436b
);
$finra-green: (
  50: #f6f9e6,
  100: #ecf3cd,
  200: #d8e79b,
  300: #c5dc69,
  400: #b1d037,
  500: #9ec405,
  600: #8db004,
  700: #00629e,
  800: #005284,
  900: #00436b
);
$finra-red: (
  50: #ffeceb,
  100: #fedad8,
  200: #fdb6b1,
  300: #fd918b,
  400: #fc6d64,
  500: #fb483d,
  600: #dd3830,
  700: #c5322b,
  800: #005284,
  900: #00436b,
  A100: #FCB6B1
);

$finra-grey: (
  50: #A7B2C2,
  100: #E9EBEF,
  200: #7A8BA3,
  300: #333333
);

$mat-card-border-radius: 10px;

// CREATE THE LIGHT THEME
// Override some of the default colors not addressed by the palette below
$mat-light-theme-background: map-merge(
  $mat-light-theme-background,
  ('dialog': mat-color($mat-grey, 200), 'background': mat-color($mat-grey, A200), 'card': white)
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darkers
// hue.
$app-primary: mat-palette($finra-blue, 500, 100, 900);
$app-accent: mat-palette($finra-light-blue, 500, A100, A400);
$app-warn: mat-palette($mat-red); // The warn palette is optional (defaults to red).

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// CREATE THE DARK THEME
// Override some of the default colors not addressed by the palette below
$mat-dark-theme-background: map-merge(
  $mat-dark-theme-background,
  ('card': mat-color($finra-blue, 900), 'hover': rgba(white, 0.1))
);

$app-dark-primary: mat-palette($finra-blue, 500, 300, 900);
$app-dark-accent: mat-palette($finra-light-blue, 500, A100, A400);
$app-dark-theme: mat-dark-theme($app-dark-primary, $app-dark-accent, $app-warn);

$black-12-opacity: rgba(black, 0.12);

$mat-menu-item-height: 36px;
$mat-toolbar-height-desktop: 75px;

$finra-typography-config: mat-typography-config(
  $font-family: 'Open Sans, sans-serif',
  $display-4: mat-typography-level(112px, 112px, 300),
  $display-3: mat-typography-level(56px, 56px, 400),
  $display-2: mat-typography-level(45px, 48px, 400),
  $display-1: mat-typography-level(34px, 40px, 400),
  $headline: mat-typography-level(26px, 36px, 600),
  $title: mat-typography-level(18px, 24px, 600),
  $subheading-2: mat-typography-level(18px, 28px, 400),
  $subheading-1: mat-typography-level(16px, 22px, 600),
  $body-2: mat-typography-level(14px, 24px, 600),
  $body-1: mat-typography-level(14px, 19px, 400),
  $caption: mat-typography-level(11px, 12px, 400),
  $button: mat-typography-level(13px, 18px, 300),
  /* Line-height must be unit-less fraction of the font-size.*/
  $input: mat-typography-level(14px, 1.125, 400)
);

$finra-datatable-header: rgba(black, 0.54);
$finra-datatable-row-divider: rgba(black, 0.1);

$finra-input-background: white;

$ease: all 0.2s ease-in-out;

$report-header-height: 75px;
$report-sidenav-level1-width: 75px;
$report-sidenav-level2-width: 230px;
