@import '~@angular/material/theming';
@import './_variables.scss';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($finra-typography-config);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

.report-dark-theme {
  @include mat-menu-theme($app-dark-theme);

  color: white;
}

@media only screen and (max-width: 600px) {
  body {
    background-color: white !important;
  }

  .mat-drawer-container,
  .mat-sidenav-container {
    background-color: white;
  }
}

body {
  background-color: mat-color(map-get($app-theme, background), background);
  margin: 0px;
  color: $black-87-opacity;
  font-size: 14px;
}

a {
  color: mat-color($finra-blue, 300);
  text-decoration: none;
}

.report-sidenav-container {
  & .report-sidenav {
    background-color: mat-color($finra-blue, 500);
    & fa-icon {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

/* .report-main-content-padding {
  padding: 0 26px;
} */

.report-main-content-bgcolor {
  background-color: white;
}

.mat-snack-bar-container {
  padding: 0.2rem 0.8rem 0.2rem 1rem !important;
  min-width: 400px !important;
  & span {
    margin-left: 0.3rem;
  }
  &.report-alert-error {
    background-color: mat-color($finra-red, 50);
    border: solid mat-color($finra-red, 500) 1px;
    border-radius: 2px;
    color: map_get(map_get($app-theme, foreground), base);
    & mat-icon {
      color: mat-color($finra-red, 500);
    }
  }
  &.report-alert-warn {
    background-color: mat-color($finra-yellow, 100);
    border: solid mat-color($finra-yellow, 700) 1px;
    border-radius: 2px;
    color: map_get(map_get($app-theme, foreground), base);
    & mat-icon {
      color: mat-color($finra-yellow, 700);
    }
  }
  &.report-alert-info {
    background-color: mat-color($finra-light-blue, 50);
    border: solid mat-color($finra-light-blue, 300) 1px;
    border-radius: 2px;
    color: map_get(map_get($app-theme, foreground), base);
    & mat-icon {
      color: mat-color($finra-light-blue, 300);
    }
  }
  &.report-alert-success {
    background-color: mat-color($finra-green, 100);
    border: solid mat-color($finra-green, 600) 1px;
    border-radius: 2px;
    color: map_get(map_get($app-theme, foreground), base);
    & mat-icon {
      color: mat-color($finra-green, 600);
    }
  }
}

.report-alert {
  background-color: #f5dbd9;
  color: map_get(map_get($app-theme, foreground), secondary-text);
  text-align: center;
  margin: 0 auto;
  padding: 5px;
}

mat-dialog-container {
  background-color: mat-color($mat-grey, 200);
}

.mat-dialog-container {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
  background-color: white !important;
  border-radius: 0.125rem !important;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.2) !important;
}

.mat-divider-vertical {
  box-sizing: border-box;
  width: 2px !important;
  height: 100% !important;
  border: 1px solid #d3d8e0;
  margin: 10px 10px 0 !important;
}

.mat-divider {
  box-sizing: border-box;
  height: 2px;
  width: 100%;
  border: 1px solid #d3d8e0;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.mat-title {
  margin: 0 0 8px;
}

mat-form-field {
  label:not(.mat-checkbox-layout) {
    font-weight: normal;
  }
}

.mat-form-field-infix {
  border-top: none;
  line-height: 16px;
}

.mat-form-field-flex {
  background-color: $finra-input-background;
  border: solid 1px mat-color(map-get($app-theme, foreground), divider);
  padding: 5px;
}

.mat-form-field-flex:active,
.mat-form-field-flex:focus-within {
  border: solid 1px mat-color($finra-light-blue, 200);
}

.mat-form-field-underline {
  height: 0px !important;
}

.mat-form-field-ripple {
  height: 0px !important;
}

.mat-chip-list {
  padding: 0 10px 0 10px;
}

.mat-chip {
  background-color: mat-color($finra-light-blue, 300) !important;
  color: mat-contrast($finra-light-blue, 300) !important;

  .mat-chip-remove {
    color: mat-contrast($finra-light-blue, 300) !important;
  }
}

mat-icon {
  // line height adjustment for font-awesome icons
  &.fas,
  &.fal,
  &.fab,
  &.far {
    line-height: 22px;
  }
}

.mat-menu-item {
  & .mat-icon {
    color: mat-color(map-get($app-theme, accent));
  }
}

label:not(.mat-checkbox-layout) {
  font-weight: bold;
  color: mat-color(map-get($app-theme, foreground), secondary-text);
}

.mat-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 15px !important; /*remove extra spacing at bottom*/
  border-radius: 3px !important;
  border: 1px solid mat-color($finra-blue, 100);
  box-shadow: none !important;

  // &.report-alert {
  // }

  .mat-card-header {
    .mat-card-title {
      @include mat-typography-level-to-styles($finra-typography-config, title);
    }
  }
}

.mat-tab-disabled {
  color: mat-color($mat-grey, 500);
}
.mat-tooltip {
  background-color: mat-color($mat-grey, 900);
}

.mat-list-item {
  height: 36px;
  line-height: 36px;
}
.mat-list-item:hover {
  background-color: mat-color($mat-grey, 100);
}

.mat-list .mat-list-item,
.mat-nav-list .mat-list-item,
.mat-selection-list .mat-list-item .mat-list-item-content {
  padding-top: 0px 10px !important;
  font-size: 14px;
}

.mat-raised-button {
  &:hover {
    opacity: 0.8;
  }
  &:active {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.3) inset;
  }
  & [type='reset'] {
    border: 1px solid mat-color($mat-grey, 300) !important;
  }
}

.mat-flat-button {
  &:hover {
    opacity: 0.8;
  }
  &:active {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.3) inset;
  }
  & [type='reset'] {
    border: 1px solid mat-color($mat-grey, 300) !important;
  }
}

.mat-expansion-panel {
  border: 1px solid mat-color($finra-blue, 100);
  border-radius: 3px;
  box-shadow: unset !important;
  margin: 15px 0 !important;

  @media (max-device-width: 640px) {
    border: unset !important;
  }

  &.mat-expanded {
    border-top-color: rgba(0, 0, 0, 0);
    box-shadow: inset 0 2px 0 0 #99cded !important;

    // for our custom expansion indicator icon
    & .report-expansion-indicator {
      transform: rotate(180deg);
    }
  }

  & .mat-expansion-panel-header {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 24px !important;

    @media (max-device-width: 640px) {
      height: auto !important;
      padding: 10px 24px 10px 0 !important;
    }

    & mat-tab-header {
      border-bottom: none;
      border-top-left-radius: 3px;

      & .mat-tab-label {
        font-size: 18px;
        font-weight: 600;
        color: map_get(map_get($app-theme, foreground), base);
        opacity: 1;
        // border-right: solid 1px mat-color(map-get($app-theme, foreground), divider);

        &:not(:first-child):not(:last-child) > .mat-tab-label-content {
          border-right: solid 1px #a7b2c2;
          padding-right: 21px;
        }

        &.mat-tab-label-active {
          background-color: mat-color($finra-light-blue, 500) !important;
          color: mat-contrast($finra-light-blue, 500) !important;
        }
      }

      mat-ink-bar {
        display: none;
      }
    }

    &[reportTabbedExpansionPanelHeader] {
      .mat-content {
        margin-left: -24px;
      }

      .mat-expansion-indicator {
        padding-bottom: 8px;
      }
    }
  }

  .mat-expansion-indicator::after {
    border-radius: 2px;
    border-style: solid;
    border-width: 0 3px 3px 0;
    color: mat-color(map-get($app-theme, accent), default);
    content: '';
    display: inline-block;
    padding: 6px;
    transform: rotate(45deg);
    vertical-align: middle;
  }

  // for nested expansion panels
  & .mat-expansion-panel {
    border: unset;
    border-top: 1px solid mat-color($finra-blue, 100);
    box-shadow: unset !important;
    margin: 0 !important;

    & .mat-expansion-panel-header {
      font-size: 14px !important;
      font-weight: 600 !important;
      padding-left: 0 !important;

      &[aria-disabled='true'] {
        color: black !important;
      }
    }

    &[aria-disabled='true'] {
      color: black !important;
    }

    & .mat-expansion-indicator::after {
      padding: 3px;
    }
  }
}

.mat-expansion-panel-content {
  padding-left: 30px;
  padding-right: 30px;

  @media (max-device-width: 640px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  & > .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

// mat-select
.mat-select {
  font-size: 14px;
  line-height: 16px;

  & .mat-select-value,
  .mat-option-text {
    color: mat-color($finra-blue, A800);
  }
}

.report-mobile-detail-nav {
  // height: 78px;
  background-color: #0082d1;
  cursor: pointer;
  color: #ffffff;
  padding: 20px;
  margin: -20px;
  font-size: 14px;
  font-weight: 600;

  .mat-chip {
    background-color: white !important;
    color: mat-color($finra-blue, 300) !important;
  }
}

.report-mobile-detail-nav-back {
  padding-right: 10px; // Need to decalre the variable for standardization of padding between image and text
  height: 16px;
  width: 9.5px;
}

.report-header-semibold {
  font-weight: 600;
  padding-right: 10px;
}

.report-header-semibold-blue {
  font-weight: 600;
  color: #0082d1;
}

hr {
  box-sizing: border-box;
  height: 2px;
  border: 1px solid #d3d8e0 !important;
}

label {
  color: #7a8ba3;
  padding-right: 10px;
}

.verticalspacer {
  margin-top: 25px;
}

.title {
  @include mat-typography-level-to-styles($finra-typography-config, headline);
  color: mat-color($finra-blue, 500);
  margin: 15px 0 3px 0;
  letter-spacing: -0.93px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .mat-sidenav-content {
    margin-left: 0px !important;
  }
}
